import { useContext } from '@nuxtjs/composition-api';
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  sameAs,
  helpers,
  minValue,
  maxValue,
  integer,
  url,
} from '@vuelidate/validators';
import gql from 'graphql-tag';

const EMAIL_IN_USE_QUERY = gql`
  query emailInUse($email: String!) {
    emailInUse(email: $email)
  }
`;

const EMAIL_DNS_CHECK = gql`
  query validateEmailDns($email: String!) {
    validateEmailDns(email: $email)
  }
`;

const MAX_STRING_LENGTH = 255;

export default function useValidators() {
  const { app } = useContext();
  return {
    helpers,
    required: helpers.withMessage('This value is required.', required),
    requiredIf,
    email: helpers.withMessage('This email is invalid.', email),
    minLength,
    maxLength,
    minValue,
    maxValue,
    integer,
    maxStringLength: maxLength(MAX_STRING_LENGTH),
    sameAs,
    subDomain: helpers.withMessage(
      'You must enter a valid subdomain, only letter a-z, numbers and hyphens(-)',
      (subDomain) => {
        if(!subDomain) {
          return false
        }
        let regex = /^[a-z0-9-]+$/i;
        return regex.test(subDomain)
      }
    ),
    validateUrl: helpers.withMessage(
      'Please use a valid url. Hint: start the link with https://',
      (inputUrl) => {
        if(!inputUrl) {
          return true;
        }
        const parsedUrl = new URL(inputUrl);
        const allowedProtocols = ['https:', 'http:'];

        return allowedProtocols.includes(parsedUrl.protocol);
      },
    ),
    emailNotInUse: helpers.withMessage(
      'This email is already in use.',
      helpers.withAsync(async (value) => {
        const res = await app.apolloProvider.defaultClient.query({
          query: EMAIL_IN_USE_QUERY,
          variables: { email: value },
        });
        return !res.data.emailInUse;
      }),
    ),
    validateEmailDns: helpers.withMessage(
      'The email DNS records failed, please try a valid email.',
      helpers.withAsync(async (value) => {
        const res = await app.apolloProvider.defaultClient.query({
          query: EMAIL_DNS_CHECK,
          variables: { email: value },
        });
        return res.data.validateEmailDns;
      }),
    ),
    dateLte: (maxDateVal, fieldName) =>
      helpers.withParams(
        { type: 'dateLte', value: maxDateVal },
        helpers.withMessage(`Must be before ${fieldName}`, (value) => {
          if (!maxDateVal) {
            return true;
          }

          return new Date(maxDateVal) >= new Date(value);
        }),
      ),
    weekendDisabled: helpers.withMessage('Invalid date - Weekends are disabled.', (value) => {
      const day = new Date(`${value} UTC`).getDay();
      if ([6, 0].includes(day)) {
        return false;
      }
      return true;
    }),
    currentDateDisabled: helpers.withMessage(
      'Invalid date - Current date is disabled.',
      (value) => {
        const date = new Date(`${value} UTC`);
        const currentDate = new Date();
        if (date.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)) {
          return false;
        }
        return true;
      },
    ),
    pastDateDisabled: helpers.withMessage('Invalid date - Past dates are disabled.', (value) => {
      const date = new Date(`${value} UTC`);
      const currentDate = new Date();
      if (date.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
        return false;
      }
      return true;
    }),
    url: helpers.withMessage('The url is not in correct format.', url),
  };
}
